import React from "react";
import { Link } from "gatsby";
import Layout from "../components/Layout/layout";
import SEO from "../components/seo";
import styled from "styled-components";

const Wrapper = styled.section`
  width: 700px;
  text-align: center;
  margin: 0 auto;
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Wrapper>
      <h1>NOT FOUND</h1>
      <p>This page doesn&#39;t exist currently.</p>
      <p>Maybe try returning to the <Link to="/">home page</Link>?</p>
    </Wrapper>
  </Layout>
);

export default NotFoundPage;
